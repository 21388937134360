import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType, Paging, ShowOrdersByArticleRequest } from "@tm/models"
import { notUndefinedOrNull, Overwrite } from "@tm/utils"
import { useMemo } from "react"
import { useInfiniteQuery, QueryFunctionContext, QueryKey } from "react-query"
import { CisFilters, CisVoucher } from "../../business/model"
import { useFilterStore } from "../../business/state"
import { FindVoucherRequest, FindVouchersResponse, ShowComplaintsRequest, ShowCompletedOrdersRequest, ShowOpenOrdersRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

const KEY = "cis_useVouchers"
type PageParam = Paging

function showVouchers(
    pageParam: PageParam | undefined,
    pageSize: number,
    customerNo: string | undefined,
    voucherTypeId: number,
    requestSingleItem: boolean,
    query?: string
) {
    const findVoucherRequest: FindVoucherRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
        voucherTypeId,
        query,
    }

    // The filterStore state MUST be called again to avoid unneeded calls when the filters component is not loaded. ie.on Refresch or call from summary (singleSearch)
    const { filters } = useFilterStore.getState()

    if (requestSingleItem) {
        findVoucherRequest.query = query
    } else if (filters) {
        findVoucherRequest.dateFrom = filters?.[voucherTypeId.toString()].dateFrom
        findVoucherRequest.dateTo = filters?.[voucherTypeId.toString()].dateTo
        findVoucherRequest.query = filters?.[voucherTypeId.toString()].query
        findVoucherRequest.queryTypeId = filters?.[voucherTypeId.toString()].queryTypeId
    }

    useFilterStore.getState().setFindVouchersRequest(findVoucherRequest)

    return Data.findVouchers(findVoucherRequest)
}

function showClosedOrders(
    pageParam: PageParam | undefined,
    pageSize: number,
    customerNo: string | undefined,
    requestSingleItem: boolean,
    orderId?: string
) {
    const showCompletedOrdersRequest: ShowCompletedOrdersRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
    }

    const { filters } = useFilterStore.getState()

    if (requestSingleItem) {
        showCompletedOrdersRequest.orderNumber = orderId
    } else if (filters) {
        const { queryTypeId, dateFrom, dateTo, query, deliveryNote, deliveryNoteDate, documentType, invoice, invoiceDate, orderNumber, orderType } =
            filters?.[CisVoucherType.ClosedOrders.toString()]

        // This would only work for STG as long as their queryTypeIds remain the same
        showCompletedOrdersRequest.dateFrom = !queryTypeId || queryTypeId === 1 ? dateFrom : undefined
        showCompletedOrdersRequest.dateTo = !queryTypeId || queryTypeId === 1 ? dateTo : undefined
        showCompletedOrdersRequest.deliveryNote = queryTypeId === 2 ? query : deliveryNote
        showCompletedOrdersRequest.deliveryNoteDate = queryTypeId === 2 ? deliveryNoteDate : undefined
        showCompletedOrdersRequest.documentType = documentType
        showCompletedOrdersRequest.invoice = queryTypeId === 3 ? query : invoice
        showCompletedOrdersRequest.invoiceDate = queryTypeId === 3 ? invoiceDate : undefined
        showCompletedOrdersRequest.orderNumber = orderId ?? (!queryTypeId || queryTypeId === 1 ? query : orderNumber)
        showCompletedOrdersRequest.orderType = orderType
    }

    return Data.showCompletedOrders(showCompletedOrdersRequest)
}

function showOpenOrders(
    pageParam: PageParam | undefined,
    pageSize: number,
    customerNo: string | undefined,
    requestSingleItem: boolean,
    orderId?: string
) {
    const showOpenOrdersRequest: ShowOpenOrdersRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
    }

    const { filters } = useFilterStore.getState()

    if (requestSingleItem) {
        showOpenOrdersRequest.orderNumber = orderId
    } else if (filters) {
        const { dateFrom, dateTo, query, orderNumber, orderType } = filters?.[CisVoucherType.OpenOrders.toString()]

        showOpenOrdersRequest.dateFrom = dateFrom
        showOpenOrdersRequest.dateTo = dateTo
        showOpenOrdersRequest.orderNumber = orderId ?? query ?? orderNumber
        showOpenOrdersRequest.orderType = orderType
    }

    return Data.showOpenOrders(showOpenOrdersRequest)
}

function showOrdersByArticle(pageParam: PageParam | undefined, pageSize: number, customerNo: string | undefined, requestSingleItem: boolean) {
    const showOrdersByArticleRequest: ShowOrdersByArticleRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
    }

    // The filterStore state MUST be called again to avoid unneeded calls when the filters component is not loaded. ie.on Refresch or call from summary (singleSearch)
    const { filters } = useFilterStore.getState()
    const { orderDate, orderStatus, orderNumber, itemId } = filters?.[CisVoucherType.ArticleSearchInOrders.toString()] ?? {}

    if (requestSingleItem && filters) {
        showOrdersByArticleRequest.itemId = itemId
    } else if (filters) {
        showOrdersByArticleRequest.itemId = itemId
        showOrdersByArticleRequest.orderNumber = orderNumber
        showOrdersByArticleRequest.orderStatus = orderStatus
        showOrdersByArticleRequest.orderDate = orderDate
    }

    return Data.showOrdersByArticle(showOrdersByArticleRequest)
}

function showComplaints(pageParam: PageParam | undefined, pageSize: number, customerNo: string | undefined) {
    const showComplaintsRequest: ShowComplaintsRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
    }

    return Data.showComplaints(showComplaintsRequest)
}

export function useVouchers(
    useFilters: boolean,
    filters: CisFilters | undefined,
    voucherTypeId: CisVoucherType,
    pageSize = 150,
    singleSearch = false,
    query?: string,
    orderId?: string
) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === voucherTypeId)
    }, [cisOptions, voucherTypeId])

    const enabled = voucherTypeEnabled && enableServiceCalls && (!useFilters || !!filters)
    // TODO: Currently the infinite query stuff is not used.
    // When using this hook in the vouchers/invoices component, call vouchersLoadNextPage to load next page.
    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
        [KEY, voucherTypeId, telesalesCustomerNo, pageSize, singleSearch, query, orderId, filters, enabled], // Filters are required for the KEY, otherwise changes of date to/from won't trigger a new search
        ({
            pageParam,
        }: QueryFunctionContext<QueryKey, PageParam>):
            | Promise<Overwrite<FindVouchersResponse, { vouchers: Array<CisVoucher> }> | undefined>
            | undefined => {
            // TODO: before this can be used in the vouchers component, the filters have to be implemented here.

            switch (voucherTypeId) {
                case CisVoucherType.ClosedOrders:
                    return showClosedOrders(pageParam, pageSize, telesalesCustomerNo, singleSearch, orderId).then(
                        (response) =>
                            response && {
                                ...response,
                                vouchers: response.completedOrders,
                            }
                    )

                case CisVoucherType.OpenOrders:
                    return showOpenOrders(pageParam, pageSize, telesalesCustomerNo, singleSearch, orderId).then(
                        (response) =>
                            response && {
                                ...response,
                                vouchers: response.openOrders,
                            }
                    )

                case CisVoucherType.ArticleSearchInOrders:
                    return showOrdersByArticle(pageParam, pageSize, telesalesCustomerNo, singleSearch).then(
                        (response) =>
                            response && {
                                ...response,
                                vouchers: response.ordersByArticle,
                            }
                    )

                case CisVoucherType.Complaints:
                    return showComplaints(pageParam, pageSize, telesalesCustomerNo).then(
                        (response) =>
                            response && {
                                ...response,
                                vouchers: response.complaints,
                            }
                    )

                // TODO: CisVoucherType.AllOrders: Currently the response type of Data.showAllOrders is quite different to
                // the other voucher data functions. For the next big refactoring we have to analyze if it makes sense to
                // include AllOrders in this hook or if we want to split up this hook completely.
                case CisVoucherType.AllOrders:
                    return undefined

                default:
                    return showVouchers(pageParam, pageSize, telesalesCustomerNo, voucherTypeId, singleSearch, query || orderId)
            }
        },
        {
            enabled,
            getNextPageParam: (lastPage): PageParam | undefined => {
                if (lastPage && lastPage.vouchers.length === lastPage.pageSize) {
                    return { pageIndex: lastPage.pageIndex + 1, pageSize: lastPage.pageSize }
                }
            },
        }
    )

    const vouchers = useMemo(() => {
        return data?.pages.flatMap((page) => page?.vouchers).filter(notUndefinedOrNull)
    }, [data?.pages])

    const vouchersLoadedMessage = useMemo(() => {
        return data?.pages?.last()?.messageDescription
    }, [data?.pages])

    return {
        vouchers,
        vouchersLoadedMessage,
        vouchersLoading: isLoading,
        vouchersLoadNextPage: hasNextPage ? fetchNextPage : undefined,
        vouchersNextPageLoading: isFetchingNextPage,
    }
}
