import { ErpPriceAdditionalInfo, Stack, Typography } from "@tm/components"
import { ErpPrice, MemoType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import PriceMemo from "./PriceMemo"
import { BasePricesProps } from "."

type Props = BasePricesProps & {
    calculatedPrice?: ErpPrice
    erpPrice?: ErpPrice
    priceLabel: string
    primaryColor?: boolean
    showPricePerUnitLabel?: boolean
}

export default function BigScreenPrice({ calculatedPrice, erpPrice, priceLabel, primaryColor, theme, quantity, showPricePerUnitLabel }: Props) {
    const fontFamilyOverwrite = theme?.overwrites?.components?.basketPrices?.fontFamily || theme.typography.fontFamily

    const { translate, currency } = useLocalization()
    const price = calculatedPrice ?? erpPrice
    if (!price) {
        return null
    }

    return (
        // here was a adjustment for this ticket, please mentation it if you change the minWidth NEXT-29186
        <Stack minWidth="10.5em" spacing={0}>
            <Typography variant="label" alignSelf="flex-start" noWrap>
                {priceLabel}
            </Typography>
            <Stack justifyContent="flex-end">
                <Stack direction="row" spacing={0.8} justifyContent="flex-start" alignItems="center">
                    <Typography
                        color={primaryColor ? "primary" : undefined}
                        fontFamily={fontFamilyOverwrite || theme.typography.fontFamily}
                        fontWeight="bold"
                        noWrap
                    >
                        {currency(price.value, price.currencySymbol || price.currencyCode_Iso_4217)}
                    </Typography>

                    {erpPrice && <ErpPriceAdditionalInfo price={erpPrice} />}
                </Stack>
                {!!price.priceUnit && showPricePerUnitLabel && (price.priceUnit > 1 || (quantity && !!quantity.quantityUnit)) && (
                    <Typography variant="label" alignSelf="flex-start">
                        {translate(1312)} {price.priceUnit} {quantity && quantity.quantityUnit}
                    </Typography>
                )}
                {price.memos
                    ?.filter((memo) => memo.type === MemoType.Note)
                    .map((memo, index) => (
                        <PriceMemo memo={memo} key={index} />
                    ))}
            </Stack>
        </Stack>
    )
}
