import { Article, RequestArticleListPayload, SearchFilters, Vehicle } from "@tm/models"
import { getArticlesFromTraderArticleDictionary, mergeDbAlternatives } from "../../list/business/helpers"
import { Repositories, Models } from "../../../data"

export async function loadAlternatives(request: RequestArticleListPayload, vehicle?: Vehicle) {
    const { traderArticleNos, articleForDbAlternatives, articleIdentifier } = request
    if (traderArticleNos) {
        const proms: [Promise<Models.GetArticlesByWholesalerArticleNosResponse> | undefined, Promise<Article[]> | undefined] = [undefined, undefined]

        if (traderArticleNos.wholesalerParts?.length) {
            const nos = traderArticleNos.wholesalerParts.map((x) => x.wholesalerArticleNumber)
            proms[0] = Repositories.getArticlesByWholesalerArticleNosWithFilters({
                wholesalerArticleNumbers: nos,
                modelId: vehicle?.tecDocTypeId,
                hideDuplicatesWithDifferentProductGroups: traderArticleNos.hideDuplicatesWithDifferentProductGroups,
            })
        }

        if (articleForDbAlternatives) {
            proms[1] = Repositories.getArticles({
                searchFilter: SearchFilters.All,
                query: articleForDbAlternatives.supplierArticleNo,
                productGroupIds: articleForDbAlternatives.productGroup?.id ? [articleForDbAlternatives.productGroup.id] : undefined,
                oeManufacturerIds: articleForDbAlternatives.supplier?.manufacturerId ? [articleForDbAlternatives.supplier.manufacturerId] : undefined,
            })
        }

        if (!proms[0] && !proms[1]) {
            return { articles: [] }
        }

        const [traderArticleResponse, additionalArticlesResponse] = await Promise.all(proms)

        const { articles, wholesalerParts } = getArticlesFromTraderArticleDictionary(
            traderArticleResponse?.results,
            traderArticleNos.wholesalerParts,
            false
        )

        mergeDbAlternatives(
            articles,
            additionalArticlesResponse,
            articleForDbAlternatives,
            !!traderArticleNos.hideDuplicatesWithDifferentProductGroups
        )

        if (!articles.length && traderArticleNos.hideArticleListWithoutResults) {
            return { articles }
        }
        return { articles, wholesalerParts }
    }

    if (articleIdentifier) {
        const proms: [Promise<Article[]> | undefined, Promise<Article[]> | undefined] = [undefined, undefined]

        if (articleIdentifier.length) {
            proms[0] = Repositories.getArticlesByArticleNoWithOptVehicle(articleIdentifier)
        }

        if (articleForDbAlternatives) {
            proms[1] = Repositories.getArticles({
                searchFilter: SearchFilters.All,
                query: articleForDbAlternatives.supplierArticleNo,
                productGroupIds: articleForDbAlternatives.productGroup?.id ? [articleForDbAlternatives.productGroup.id] : undefined,
                oeManufacturerIds: articleForDbAlternatives.supplier?.manufacturerId ? [articleForDbAlternatives.supplier.manufacturerId] : undefined,
            })
        }

        if (!proms[0] && !proms[1]) {
            return { articles: [] }
        }

        const [articles = [], additionalArticlesResponse] = await Promise.all(proms)

        mergeDbAlternatives(articles, additionalArticlesResponse, articleForDbAlternatives, false)

        return { articles }
    }

    return { articles: [] }
}
