import { useState, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { MoreMenu, MoreMenuItem } from "@tm/controls"
import { ArticleInfoType, ConfigParams, ConfirmationDialogKeys, ErpSystemConfigMode, NoteTypes } from "@tm/models"
import { createQueryString, encodeUniqueId, renderRoute, useErpConfig } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { Stack, Checkbox, Button, Typography, Icon, Tooltip, NotesButton, useTheme, useMediaQuery } from "@tm/components"
import { getBundleParams } from "../../../../../../utils"
import DeleteButton from "../../../../DeleteButton"
import { BasketPart, PartListItemActions } from "../../../../../../models"
import PartType from "../../../../PartType"

type Props = {
    disableControls?: boolean
    hideVehicleLogos?: boolean
    part: BasketPart
    showItemNote?: boolean
    showOrderOptions: boolean
    workTaskId: string
    onExpandNote?(id: string): void
    onExpandOrderOptions?(id: string): void
    onOpenAlternativeArticlesToReplacePart?(part: BasketPart): void
    onOpenArticleDetails?(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number, subPage?: string, scrollTo?: string): void
    onOpenArticleSearch?(query: string): void
    onOpenDirectSearchToReplacePart?(part: BasketPart): void
    onOpenUniversalSearchToReplacePart?(part: BasketPart): void
    onRemoveParts?(partIds: string[]): void
    onSelectPart?(partId: string): void
}

export default function PartActions(props: Props) {
    const { disableControls, part, workTaskId, showOrderOptions, hideVehicleLogos, showItemNote } = props
    const { partItem, erpInfoResponse, states } = part
    const { id, articleInformation, orderItem } = partItem
    const { supplierId, productGroupId, description, articleNumber, articleInfoType, wholesalerArticleNumber } = articleInformation
    const { mode } = useErpConfig()
    const { translateText } = useLocalization()
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))
    const { partnerChangeRoute } = getBundleParams()
    const { replacePartUniversalSearchUrl } = Morpheus.getParams<ConfigParams>()

    // ELEKAT - CARAT uses this feature
    const { showPartnerChange, openPartnerChange } = useMemo(() => {
        if (partnerChangeRoute && mode === ErpSystemConfigMode.Partnersystems) {
            let openPartnerChange
            if (supplierId && productGroupId && articleNumber) {
                openPartnerChange = () => {
                    const url =
                        renderRoute(partnerChangeRoute, { workTaskId: encodeUniqueId(workTaskId) }) +
                        createQueryString({
                            basketItemId: partItem.id,
                            supplierId,
                            supplierArticleNo: articleNumber,
                            productGroupId,
                        })

                    Morpheus.showView("1", url)
                }
            }

            return { showPartnerChange: true, openPartnerChange }
        }
        return { showPartnerChange: false }
    }, [workTaskId, partItem.id, supplierId, productGroupId, articleNumber, mode, partnerChangeRoute])

    function handleReplacePartClick() {
        if (articleInfoType === ArticleInfoType.TecdocArticle) {
            props.onOpenAlternativeArticlesToReplacePart?.(part)
        }
    }

    function handleUniversalSearchClick() {
        props.onOpenUniversalSearchToReplacePart?.(part)
    }

    function handleDirectSearchClick() {
        props.onOpenDirectSearchToReplacePart?.(part)
    }

    function handleMenuItemSelect(menuItem: MoreMenuItem) {
        if (menuItem.id === PartListItemActions.Details) {
            props.onOpenArticleDetails?.(productGroupId, articleNumber, supplierId)
        }
    }

    // STG uses this feature
    function renderOrderOptions() {
        let icon = ""
        if (erpInfoResponse?.orderOptions?.billingAddresses && !erpInfoResponse.orderOptions.billingAddresses.isNotVisible) {
            // OrderOptions and BillingAddresses must explicitly be proved
            icon = /* erpInfo.selectedOrderOptions ? "order-options-edited" : */ "order-options"
        } else if (erpInfoResponse?.orderOptions?.shipmentModes && !erpInfoResponse.orderOptions.shipmentModes.isNotVisible) {
            // OrderOptions and BillingAddresses must explicitly be proved
            icon = /* selectedOrderOptions ? "truck-edit" : */ "truck"
        } else {
            return
        }

        return (
            <Tooltip className="basket-memo_tooltip" title={translateText(12417)}>
                <Button
                    startIcon={<Icon name={icon} />}
                    variant="text"
                    // color={selectedOrderOptions ? "highlight" : undefined}
                    onClick={() => props.onExpandOrderOptions?.(erpInfoResponse.itemId)}
                />
            </Tooltip>
        )
    }

    function renderMoreMenu() {
        const menuItems: Array<MoreMenuItem> = []
        if (articleInfoType !== ArticleInfoType.CustomArticle && articleInfoType !== ArticleInfoType.OeArticle) {
            menuItems.push({
                icon: "search",
                id: PartListItemActions.Details,
                text: translateText(43),
            })
        }

        return (
            <MoreMenu
                items={menuItems}
                onMenuItemSelect={handleMenuItemSelect}
                disabled={disableControls || articleInfoType === ArticleInfoType.WholesalerArticle}
            />
        )
    }

    return (
        <Stack direction="row" spacing={isBigScreen ? 1 : 0} alignItems="center">
            {showOrderOptions && renderOrderOptions()}
            <PartType
                part={part}
                replacedPartInfo={orderItem?.replacedPartInfo}
                hideVehicleLogos={hideVehicleLogos}
                onOpenArticleSearch={props.onOpenArticleSearch}
            />
            {showItemNote && (
                <NotesButton
                    articleNotes={[
                        {
                            type: NoteTypes.ARTICLE,
                            message: orderItem?.memo,
                            title: translateText(12874),
                        },
                    ]}
                    onClick={() => props.onExpandNote?.(id)}
                    hasNote={!!orderItem?.memo}
                    iconOnly
                />
            )}
            <Tooltip
                title={
                    !disableControls && (
                        <>
                            <Typography variant="label">{translateText(articleInfoType !== ArticleInfoType.TecdocArticle ? 1508 : 1507)}</Typography>
                            {articleInfoType !== ArticleInfoType.TecdocArticle && (
                                <Stack spacing={0.5}>
                                    {!!replacePartUniversalSearchUrl && (
                                        <Button variant="outlined" onClick={handleUniversalSearchClick} disabled={!description}>
                                            {translateText(1009)}
                                        </Button>
                                    )}
                                    <Button
                                        variant="outlined"
                                        onClick={handleDirectSearchClick}
                                        disabled={!articleNumber && !wholesalerArticleNumber}
                                    >
                                        {translateText(840)}
                                    </Button>
                                </Stack>
                            )}
                        </>
                    )
                }
                variant="light"
            >
                <Button onClick={handleReplacePartClick} startIcon={<Icon name="replace" />} variant="text" disabled={disableControls} />
            </Tooltip>
            {showPartnerChange && (
                <Button
                    title={translateText(12643)}
                    onClick={openPartnerChange}
                    disabled={!openPartnerChange || disableControls}
                    startIcon={<Icon name="replace-partner" />}
                    variant="text"
                />
            )}
            <DeleteButton
                ids={[id]}
                buttonTitleId={624}
                disableButton={disableControls}
                dialogTextId={833}
                onConfirmRemove={(ids) => ids && props.onRemoveParts?.(ids)}
                confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
            />
            {renderMoreMenu()}
            <Checkbox checked={states.isSelected} onChange={() => props.onSelectPart?.(id)} disabled={disableControls} />
        </Stack>
    )
}
