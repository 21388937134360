import { RequestArticleListPayload, Vehicle } from "@tm/models"
import { loadAlternatives } from "./loadAlternatives"
import { loadPartsList } from "./loadPartsList"
import { getAccessoryList, getArticlesByArticleNoWithOptVehicle } from "../../../data/repositories"
import { PureListArticlesResponse } from "../../ListV2/models"

export async function loadArticles(type: string, request: RequestArticleListPayload, vehicle?: Vehicle): Promise<PureListArticlesResponse> {
    const { traderArticleNos, partsList, accessoryList, articleIdentifier, articles } = request

    if (type === "alternative" && (traderArticleNos || articleIdentifier)) {
        return {
            type,
            ...(await loadAlternatives(request, vehicle)),
        }
    }

    if (type === "partslist" && partsList) {
        return {
            type,
            ...(await loadPartsList(request)),
        }
    }

    if (type === "accessoryList" && accessoryList) {
        return {
            type,
            articles: await getAccessoryList(accessoryList),
        }
    }

    if (type === "articlesAlreadyOrderedList" && articleIdentifier) {
        return {
            type,
            articles: await getArticlesByArticleNoWithOptVehicle(articleIdentifier),
        }
    }

    if (type === "mainAccessoryList" && articles) {
        return {
            type,
            articles,
        }
    }

    if (type === "recommended-articles" && articles) {
        return {
            type,
            articles,
        }
    }

    return { type: null, articles: [] }
}
