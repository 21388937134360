import { useCallback, useEffect, useMemo, useState } from "react"
import { Article, channel, DefaultArticleItemState } from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUpdateArticle } from "../../../../../compilations/src/data/hooks/useCompilations"
import AvailabilityComponent from "../../../../../erp/src/_shared/availability-wrapper"
import {
    useHandleClickDetails,
    useHandleClickAvailability,
    useHandleClickImage,
    useShowArticleDetails,
    useHandleClickReference,
    useArticleImages,
    useDisplayArticle,
    useGetRepairTimesUrl,
    useArticleActions,
    useArticleExtendedDeliveryInfo,
    useArticleNotifications,
    useArticleOptions,
} from "../../ListV2/hooks"

import { useArticleListConfiguration } from "../../ListV2/ArticleListConfiguration"
import { createUpdateRequest } from "../helpers"
import { useArticleSelection } from "../../../../../compilations/src/components/compilations/components/details/providers/ArticleSelectionProvider"
import { useDmsArticleInfo } from "../../../../../dms/src/hooks/useDmsArticleInfo"
import { useArticleBasketActions } from "../../PureList/hooks"
import { useCompilationArticleNotes } from "../hooks/useCompilationArticleNotes"
import { useErpInfosContext, usePreviouslyOrderedArticlesContext, useProductGroupRepairTimesDataContext } from "../../ListV2/ContextProvider"

type CompilationArticleItemProps = {
    article: Article
    compilationId: string
}

export function CompilationArticleItem({ article, compilationId }: CompilationArticleItemProps) {
    const { translateText } = useLocalization()

    const { feedbackConfiguration, imsIcon } = useArticleListConfiguration()
    const options = useArticleOptions(article)
    const productGroupRepairTimesData = useProductGroupRepairTimesDataContext()
    const erpInfosData = useErpInfosContext()
    const { hideExtendedDeliveryComponent, enableArticleNotes } = useArticleListConfiguration()
    const previouslyOrderedArticles = usePreviouslyOrderedArticlesContext()

    const [expanded, setExpanded] = useState(!options.compactView)

    useEffect(() => {
        setExpanded(!options.compactView)
    }, [options.compactView])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const { updateArticle } = useUpdateArticle()

    const displayArticle = useDisplayArticle(article, [], undefined, false)

    const repairTimeProviders = productGroupRepairTimesData?.productGroupRepairTimes?.productGroups[article.productGroup.id]

    const {
        originalQuantity,
        articleQuantity,
        basketQuantity,
        articleErpInfos,
        addButtonsDisabled,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleChangeQuantity: handleChangeQuantityInternal,
    } = useArticleBasketActions(article)

    const showArticleDetails = useShowArticleDetails()

    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const handleClickAvailability = useHandleClickAvailability(article, articleQuantity, erpInfosData)
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)

    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const articleImages = useArticleImages(article)

    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(!hideExtendedDeliveryComponent, articleErpInfos.default)
    const dmsArticleInfo = useDmsArticleInfo(article)

    const notifications = useArticleNotifications(articleErpInfos.default)
    const notes = useCompilationArticleNotes(!!enableArticleNotes, compilationId, article, articleQuantity)

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    const getRepairTimesUrl = useGetRepairTimesUrl()
    const repairTimesUrl = useMemo(
        () => (!repairTimeProviders?.length ? undefined : getRepairTimesUrl(article, repairTimeProviders)),
        [repairTimeProviders, getRepairTimesUrl, article]
    )

    const handleChangeQuantity = useCallback(
        (quantity: number, loadErpInfo = true) => {
            updateArticle(createUpdateRequest(compilationId, article, quantity, notes.notes.first()?.text ?? ""))

            handleChangeQuantityInternal(quantity, loadErpInfo)
        },
        [updateArticle, handleChangeQuantityInternal, compilationId, article, notes.notes]
    )

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const selection = useArticleSelection()

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: enableArticleNotes,
        listType: "default",
        notes: notes.notes,
        repairTimesUrl,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
    })

    const articleState = useMemo<DefaultArticleItemState>(
        () => ({
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            isVehicleDependent: false,
            imsIcon,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            quantity: articleQuantity,
            selection,
            showImageViewer,
            type: "default",
            watchList: undefined,
            basketQuantity,
            showExtendedAvailabilityContainer: undefined,
            addToBasketButtonDisabled: addButtonsDisabled,
            addToCostEstimationButtonDisabled: addButtonsDisabled,

            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
            AvailabilityComponent,
        }),
        [
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            articleQuantity,
            selection,
            showImageViewer,
            basketQuantity,
            addButtonsDisabled,
            imsIcon,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
        ]
    )

    return (
        <>
            <ArticleItemStateProvider value={articleState}>
                <ArticleItem variant="default" />
            </ArticleItemStateProvider>
        </>
    )
}
