import { useCallback, useEffect } from "react"
import { renderRoute, useSelection } from "@tm/utils"
import { CisVoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { Typography, Loader, Stack } from "@tm/components"
import { useHistory, useLocation, useParams } from "react-router"
import { Voucher, OpenOrderListItem, CompletedOrderListItem, AllOrderListItem } from "../../data/model"
import { getBundleParams } from "../../utils"
import { showOrderPdf } from "./business/helpers"
import { CisDisplayMode } from "../../business/model"
import { useFilterStore } from "../../business/state"
import { useVouchers } from "../../data/hooks/useVouchers"
import { useAllOrders } from "../../data/hooks/useAllOrders"
import { NoResultHint } from "../_shared/NoResultHint"
import WmVouchers from "./components/wm/WmVouchers"
import StgVouchers from "./components/stg/StgVouchers"
import PvVouchers from "./components/pv/PvVouchers"
import StandardVouchers from "./components/standard/StandardVouchers"

type Props = {
    searchRequiredForVoucherTypes?: Array<number>
    cisDisplayMode?: CisDisplayMode
}

export type VoucherRouteProps = {
    voucherTypeId: string
    id?: string
    subId?: string
}

export const COMPACT_WIDTH = "440px"
export const FULL_WIDTH = "100%"
export const CALCULATED_HEIGHT = "calc(100vh - 18.5em)"

const stableArrayFunction = () => []
export default function VouchersComponent({ searchRequiredForVoucherTypes, cisDisplayMode }: Props) {
    const { translateText } = useLocalization()
    const location = useLocation()
    const params = useParams<VoucherRouteProps>()
    const history = useHistory()
    const { selectedIds: openedConnectedVouchers, toggleSelected: toggleOpenedConnectedVouchers } = useSelection(stableArrayFunction)
    const { cisVoucherUrl, printing } = getBundleParams()

    const voucherTypeId = parseInt(params.voucherTypeId)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { userSettings } = useUser()

    const queryParams = new URLSearchParams(location.search)
    const singleSearch = !!queryParams.get("singleSearch")
    const [filters, loading, setLoading, setHasData] = useFilterStore((store) => [store.filters, store.loading, store.setLoading, store.setHasData])

    const { vouchers, vouchersLoading, vouchersLoadNextPage, vouchersNextPageLoading, vouchersLoadedMessage } = useVouchers(
        !singleSearch, // This parameter must be set or the hook will make a duplicated call
        filters,
        voucherTypeId,
        undefined,
        !!singleSearch,
        undefined,
        singleSearch ? params.id : undefined
    )
    const { allOrders, allOrdersLoading, allOrdersLoadNextPage, allOrdersNextPageLoading } = useAllOrders(
        !singleSearch, // This parameter must be set or the hook will make a duplicated call
        filters,
        singleSearch
    )

    useEffect(() => {
        setLoading(vouchersLoading || allOrdersLoading)
    }, [vouchersLoading, allOrdersLoading])

    useEffect(() => {
        setHasData(!!vouchers?.length || !!allOrders?.length)
    }, [vouchers?.length, allOrders?.length])

    // When the voucher/order search returns only one item, the order details of it must be shown automatically
    useEffect(() => {
        let voucherId = ""
        let voucherSubId = null
        if (allOrders?.length === 1 && voucherTypeId === CisVoucherType.AllOrders) {
            const order = allOrders[0] as AllOrderListItem
            voucherId = order.orderId
        } else if (vouchers?.length === 1) {
            if (voucherTypeId === CisVoucherType.ClosedOrders || voucherTypeId === CisVoucherType.OpenOrders) {
                const order = vouchers[0] as OpenOrderListItem | CompletedOrderListItem
                voucherId = order.orderNumber
                voucherSubId = order.orderSubNumber
            } else {
                const voucher = vouchers[0] as Voucher
                voucherId = voucher.voucherId
            }
        }

        if (voucherId) {
            let url = renderRoute(cisVoucherUrl, {
                ...params,
                voucherTypeId,
                id: voucherId,
                subId: voucherSubId,
            })

            url += location.search
            url = encodeURI(url)

            if (encodeURI(`${location.pathname}${location.search}`) !== url) {
                history.push(url)
            }
        }
    }, [vouchers, allOrders, history, voucherTypeId, params, cisVoucherUrl, location.search, location.pathname])

    function loadNextPage() {
        vouchersLoadNextPage && vouchersLoadNextPage()
    }

    function loadNextOrderPage() {
        allOrdersLoadNextPage && allOrdersLoadNextPage()
    }

    function handleOpenNewVoucher(newVoucherTypeId: number, newVoucherId: string) {
        let url = renderRoute(cisVoucherUrl, {
            ...params,
            voucherTypeId: newVoucherTypeId,
            id: newVoucherId,
        })

        url = encodeURI(url)

        if (encodeURI(`${location.pathname}`) !== url) {
            history.push(url)
        }
    }

    function handlePrintOrderClick(
        orderNumber: string,
        orderSubNumber: string,
        voucherId: string,
        warehouseId: string,
        voucherTypeId?: CisVoucherType
    ) {
        showOrderPdf(
            printing,
            translateText,
            userSettings?.showPurchasePrice,
            telesalesCustomerNo,
            orderNumber,
            orderSubNumber,
            voucherId,
            warehouseId,
            voucherTypeId
        )
    }

    const getVoucherContent = useCallback(() => {
        let contentVoucher
        if (cisDisplayMode === "stg") {
            contentVoucher = (
                <StgVouchers
                    vouchers={vouchers}
                    voucherTypeId={voucherTypeId}
                    vouchersLoadedMessage={vouchersLoadedMessage}
                    onLoadNextPage={loadNextPage}
                    onPrintButtonClick={handlePrintOrderClick}
                />
            )
        } else if (cisDisplayMode === "wm") {
            contentVoucher = (
                <WmVouchers
                    endOfList={!vouchersLoadNextPage}
                    openedConnectedVouchers={openedConnectedVouchers}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Array<Voucher>}
                    handleExpandRowClick={toggleOpenedConnectedVouchers}
                    loadNextPage={loadNextPage}
                />
            )
        } else {
            contentVoucher = (
                <StandardVouchers
                    cisDisplayMode={cisDisplayMode}
                    endOfList={!vouchersLoadNextPage}
                    openedConnectedVouchers={openedConnectedVouchers}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Array<Voucher>}
                    onExpandRowClick={toggleOpenedConnectedVouchers}
                    loadNextPage={loadNextPage}
                    onOpenNewVoucher={handleOpenNewVoucher}
                />
            )
        }

        return contentVoucher
    }, [cisDisplayMode, vouchers, voucherTypeId, vouchersLoadedMessage, openedConnectedVouchers])

    const getContent = useCallback(() => {
        if (vouchers?.length) {
            return getVoucherContent()
        }
        if (allOrders?.length) {
            return <PvVouchers vouchers={allOrders} voucherTypeId={voucherTypeId} onLoadNextPage={loadNextOrderPage} />
        }

        if (
            searchRequiredForVoucherTypes &&
            searchRequiredForVoucherTypes.indexOf(voucherTypeId) !== -1 &&
            (!filters || (!filters.itemId && !filters.orderNumber && !filters.dateTo && !filters.dateFrom))
        ) {
            return <Typography>{translateText(327)} </Typography>
        }
        return <NoResultHint />
    }, [allOrders, filters, getVoucherContent, loadNextOrderPage, searchRequiredForVoucherTypes, translateText, voucherTypeId, vouchers?.length])

    return (
        <Stack flex="auto" spacing={1}>
            {loading ? <Loader /> : getContent()}
            {(vouchersNextPageLoading || allOrdersNextPageLoading) && <Loader />}
        </Stack>
    )
}
